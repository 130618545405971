<template>
  <TWDialogWrapper :show="dialog">
    <DialogPanel
      :class="breakpoint.xs ? 'pa-4' : 'pa-6'"
      class="text-left rounded bg-white relative mx-4 w-11/12 md:w-4/12"
    >
      <div class="flex justify-between items-center">
        <DialogTitle as="span" class="text-black font-bold text-xl">
          How would you like to provide feedback?
        </DialogTitle>
      </div>
      <div :class="breakpoint?.xs && 'overflow-y-auto max-h-[60vh] pr-2'">
        <div class="absolute top-5 md:top-7 right-3 text-dark hover:text-primary">
          <button @click="dialog = false">
            <Icon class="mx-1 font-bold size-8" icon="mdi-close-circle-outline" />
          </button>
        </div>
        <div
          class="flex-col md:flex-row flex justify-between items-center pa-8 md:px-8 gap-4 md:gap-8"
        >
          <div class="w-full md:w-1/2 md:mb-0">
            <a
              href="https://superscout.canny.io"
              target="_black"
              class="flex flex-col items-center justify-center w-full h-48 text-primary ring-2 ring-primary px-4 py-6 rounded hover:bg-purple-50"
            >
              <Icon icon="mdi-lightbulb-on-outline" class="size-24"></Icon>
              <span class="text-lg pt-2 text-center"> Suggest changes publicly </span>
            </a>
          </div>
          <div class="w-full md:w-1/2 md:mb-0">
            <button
              @click="mailTo('support@superscout.ai')"
              class="flex flex-col items-center justify-center w-full h-48 text-primary ring-2 ring-primary px-4 py-6 rounded hover:bg-purple-50"
            >
              <Icon icon="mdi-email-outline" class="size-24"></Icon>
              <span class="text-lg pt-2"> Email us privately </span>
            </button>
          </div>
        </div>
      </div>
    </DialogPanel>
  </TWDialogWrapper>
</template>
<script>
import TWDialogWrapper from '@components/TWDialogWrapper.vue'
import { DialogPanel, DialogTitle } from '@headlessui/vue'
export default {
  name: 'FeedbackModel',

  data() {
    return {
      dialog: false
    }
  },

  components: {
    DialogPanel,
    DialogTitle,
    TWDialogWrapper
  },

  mounted() {
    this.emitter.on('openDialog', (val) => {
      this.dialog = val
    })
  },

  beforeUnmount() {
    this.emitter.off('openDialog', (val) => {
      this.dialog = val
    })
  }
}
</script>

<style>
.close-btn {
  top: 0;
  right: 0;
}
.feedback-btn {
  border: 2px solid !important;
}
</style>
