import './assets/scss/index.scss'
import '@assets/css/styles.css'
import '@assets/css/button-classes.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { EventService } from '@/services'

import App from './App.vue'
import router from './router'

import vuetify from './plugins/vuetify'
import globalMixin from './mixins/global.mixin'
import RouterMixin from './mixins/router.mixin'
import UserMixin from './mixins/user.mixin'
import SegmentMixin from './mixins/segment.mixin'

import { Icon } from '@iconify/vue'
import VeeValidatePlugin from './plugins/validation'
import { auth0 } from './auth0'

const app = createApp(App)
app.use(vuetify)

import VueCookies from 'vue-cookies'
const options = { expires: 2 * 30 * 24 * 60 * 60 * 1000, secure: true, sameSite: 'None' }
if (import.meta.env.VITE_APP_NODE_ENV !== 'dev') {
  options.domain = import.meta.env.VITE_APP_DOMAIN
}
app.use(VueCookies, options)

// register emitter
app.config.globalProperties.emitter = EventService
app.config.globalProperties.baseURL = import.meta.env.VITE_APP_BASE_URL
app.config.globalProperties.assetURL = import.meta.env.VITE_APP_ASSET_URL

app.mixin(globalMixin)
app.mixin(RouterMixin)
app.mixin(UserMixin)
app.mixin(SegmentMixin)

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

app.use(VeeValidatePlugin)

app.use(pinia)
app.use(router)

app.component('Icon', Icon)
app.use(auth0)
app.mount('#app')
