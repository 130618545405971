import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import merge from 'lodash/merge'
import forEach from 'lodash/forEach'
import reduce from 'lodash/reduce'
import every from 'lodash/every'

export class Base {
  clone(item) {
    return cloneDeep(item)
  }

  get(key) {
    if (key) {
      return get(this, key)
    } else {
      const that = cloneDeep(this)
      ;(this.excludes || []).forEach((key) => {
        delete that[key]
      })

      delete that.excludes

      const obj = rGet(that)

      delete obj.ignoreKeys

      return obj
    }
  }

  set(object) {
    if (object) {
      merge(this, cloneDeep(object))
    }
  }

  sSet(_object) {
    if (_object) {
      rSet(this, cloneDeep(_object))
    }
  }
  safeSet(contact = {}) {
    Object.keys(contact).forEach((key) => {
      if (this.hasOwnProperty(key)) {
        this[key] = contact[key];
      }
    });
  }

  safeRemove(array, index) {
    if (array.length > 1) {
      array.splice(1, index)
    }
  }
}

function rSet(that, object) {
  forEach(that, (value, key) => {
    if (typeof value == 'object') {
      if (value && object[key]) {
        rSet(that[key], object[key])
      }
    } else {
      that[key] = object[key]
    }
  })
}

// TODO: need to change identifier, _ is used in mongodb, etc..
function rGet(item) {
  return reduce(
    item,
    (obj, x, key) => {
      if (key != '_id' && key.toString().startsWith('_')) {
        // delete item[key];
      } else if ((item.ignoreKeys || []).includes(key)) {
        obj[key] = x
      } else if (typeof x == 'object' && !(x instanceof Date) && !(x instanceof File)) {
        if (Array.isArray(x)) {
          if (every(x, (z) => typeof z == 'object' && every(x, (y) => y === undefined))) {
            obj[key] = [] // delete blank array of object ([{}])
          } else {
            obj[key] = rGet(x)
          }
        } else if (every(x, (y) => y === undefined)) {
          // obj[key] = {};
          // delete item[key]; // delete blank object ({})
        } else if (typeof x.get == 'function') {
          obj[key] = x.get()
        } else {
          obj[key] = rGet(x)
        }
      } else {
        obj[key] = x
      }

      return obj
    },
    Array.isArray(item) ? [] : {}
  )
}
