export const COLORS = {
  PRIMARY: '#6e0d5e',
  LIGHT: '#FFFFFF',
  DARK: '#121212',
  GREY: '#9E9E9E'
}

export const VENUE_SORTING = {
  RESPONSE_TIME: 'response-time',
  SEARCH_RELEVANCE: 'search-relevance',
  VERIFIED: 'verified',
  DISTANCE: 'distance',
  POPULARITY: 'popularity',
  PRICE_LOW_TO_HIGH: 'price-low-to-high',
  PRICE_HIGH_TO_LOW: 'price-high-to-low'
}

export const VENUE_SORTING_OPTIONS = [
  // { value: VENUE_SORTING.RESPONSE_TIME, text: 'Response time' },
  { value: VENUE_SORTING.SEARCH_RELEVANCE, text: 'Search relevance' },
  // { value: VENUE_SORTING.VERIFIED, text: 'Verified' },
  { value: VENUE_SORTING.DISTANCE, text: 'Distance' }
  // { value: VENUE_SORTING.POPULARITY, text: 'Popularity' },
  // { value: VENUE_SORTING.PRICE_LOW_TO_HIGH, text: 'Price Low to High' },
  // { value: VENUE_SORTING.PRICE_HIGH_TO_LOW, text: 'Price High to Low' }
]

export const REGEX = {
  PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/,
  EMAIL:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  PHONE: /([0-9\s\-]{7,})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/,
  PHONE_UK:
    /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?#(\d{4}|\d{3}))?$/,
  POSTCODE:
    /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})$/,
  URL: /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/,
  MATTERPORT_URL: /^https:\/\/my.matterport\.com\/show\/\?m=/
}

export const FILE_SOURCE = {
  ACCOUNT: 'account',
  DEMO: 'demo',
  LOCATION: 'location',
  COLLECTION: 'collection'
}

const mag = 'Are you sure you want to '

export const MESSAGES = {
  MAX_UPLOAD_LIMIT: 'You can not upload more than {replaceValue} File.',
  UPLOAD_MORE: 'Do you want to upload more images?',
  EXEEDED_FREE_LIMIT: "you've reached your free upload limit.",
  WILL_LOST_STEP_DATA: 'Are you sure? You will loss your step {replaceValue} data.',
  CANCEL_LOCATION:
    'You will lose the changes you made since you last saved. Are you sure you wish to continue?',
  REMOVE_CONTENT: mag + 'remove this content?',
  OPEN_SPACE: mag + 'show this location?',
  CLOSE_SPACE: mag + 'hide this location?',
  DELETE_LOCATION: mag + 'delete this location?',
  DELETE_INVITATION: mag + 'delete this invitation?',
  REMOVE_USER: mag + 'remove this user?',
  TRANSFER_OWNER: mag + 'transfer ownership?',
  START_TAGGING: mag + 'start keywording remaining photos?',
  REMOVE_CONTACT: mag + 'remove this contact?',
  DELETE_COLLECTION: mag + 'remove this collection?',
  DELETE_LINK: mag + 'delete this link?',
  REMOVE_COLLECTION_LOCATION: mag + 'remove this location from this collection?',
  DELETE_PROPERTY: mag + 'delete this property?',
  REMOVE_FILES: mag + 'remove these files?',
  LOCATION_SAVED_CREATE_NEW:
    'Location details have been successfully submitted. Would you like to submit another location?',
  RESET_GUEST_USER: mag + 'reset user details?',
  PROCEED_UPLOADING_LOCATION: 'Do you wish to proceed with uploading the location?',
  RESET_TAGS: mag + ' delete any custom tags you have added?'
}

export const VENUE_STATUS = {
  DRAFT: 'draft',
  OPEN: 'open',
  CLOSED: 'closed'
}

export const VENUE_STATUS_LABEL = {
  [VENUE_STATUS.DRAFT]: 'Draft',
  [VENUE_STATUS.OPEN]: 'Visible',
  [VENUE_STATUS.CLOSED]: 'Hidden'
}

export const SOCIAL_LINK_OPTIONS = [
  {
    label: 'Instagram',
    key: 'instagram',
    url: 'https://www.instagram.com/',
    icon: 'mdi-instagram',
    color: '#6e0d5e'
  },
  {
    label: 'Twitter',
    key: 'twitter',
    url: 'https://twitter.com/',
    icon: 'mdi-twitter',
    color: '#1DA1F2'
  },
  {
    label: 'Facebook',
    key: 'facebook',
    url: 'https://www.facebook.com/',
    icon: 'mdi-facebook',
    color: '#316FF6'
  },
  {
    label: 'Youtube',
    key: 'youtube',
    url: 'https://www.youtube.com/channel/',
    icon: 'mdi-youtube',
    color: '#CD201F'
  },
  {
    label: 'TikTok',
    key: 'tiktok',
    url: 'https://www.tiktok.com/',
    icon: 'tiktok'
  },
  {
    label: 'Maps listing',
    key: 'googleMaps',
    url: 'https://www.google.com/maps/',
    icon: 'mdi-google-maps'
  }
]

export const LINK_MEDIA_MANAGE = {
  SHOW: 'show',
  HIDE: 'hide',
  HIDE_ALL: 'hide_all'
}

export const LINK_STATUS = {
  DRAFT: 'draft',
  PUBLISHED: 'published',
  UNPUBLISHED: 'unpublished'
}

export const OPERATION_TYPE = { SET: 'set', UNSET: 'unset' }

export const LINK_MANAGE = {
  SHOW: 'show',
  HIDE: 'hide'
}

export const LINK_TYPE = {
  LOCATION: 'location',
  COLLECTION: 'collection'
}

export const COLLECTION_TYPE = {
  LOCATION: 'location',
  COLLECTION: 'collection'
}

export const VENUE_TYPE = {
  LOCATION: 'location',
  PROPERTY: 'property'
}

export const MEDIA_TYPE = {
  IMAGE: 'image',
  VIDEO: 'video',
  DOCUMENT: 'document',
  AUDIO: 'audio'
}

export const CATEGORY_NAME = {
  IMAGE_AND_VIDEO: 'image_and_video',
  DOCUMENT: 'document',
  GROUP: 'group'
}

export const LOCATION_STAGE = {
  PENDING: 'pending',
  APPROVED: 'approved',
  REJECTED: 'rejected',
  FINALIZED: 'finalized'
}

export const MEDIA_STATUS = {
  PENDING: 'pending',
  SUCCESS: 'success',
  FAIL: 'fail'
}

export const INTEGRATED_TREE_STATUS = {
  PENDING: 'pending',
  SUCCESS: 'success',
  FAIL: 'fail'
}

export const INTEGRATED_TREE_TYPE = {
  COLLECTION: 'Collection',
  LOCATION: 'Location'
}
