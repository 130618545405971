<template>
  <div>
    <v-app>
      <TheHeader :key="key" />

      <div>
        <v-main>
          <AuthDialog />
          <router-view />
        </v-main>
      </div>

      <v-footer v-if="showFooter" app id="sup-footer">
        <TheFooter />
      </v-footer>
    </v-app>
  </div>
  <FeedbackModel />
</template>
<script>
import TheHeader from '@components/TheHeader.vue'
import TheFooter from '@components/TheFooter.vue'
import AuthDialog from '@components/auth/AuthDialog.vue'
import FeedbackModel from '@components/FeedbackModel.vue'
import { AuthService, StorageService } from '@services'

export default {
  components: {
    TheHeader,
    TheFooter,
    AuthDialog,
    FeedbackModel
  },

  data() {
    return {
      key: 0
    }
  },

  created() {
    const sessionAccountId = StorageService.getSessionItem('accountId')
    const _accountId =
      sessionAccountId || this.$route?.query?.accountId || this.$route?.params?.accountId
    this.setAccountId(_accountId)

    if (this.$route?.query?.accountId) {
      // this.setAccountId(this.$route?.query?.accountId)
      delete this.$route?.query?.accountId
    }
    this.$replaceState({ ...this.$route })
  },

  mounted() {
    this.getVersion()
    this.emitter.on('updateHeader', this.updateHeader)
  },
  beforeUnmount() {
    this.emitter.off('updateHeader', this.updateHeader)
  },
  updated() {
    this.getVersion()
  },
  computed: {
    showFooter() {
      return [
        'location.photos.view',
        'photos.view',
        'location.edit',
        'location.create',
        'location.share',
        'location.share.edit',
        'external-data-sync',
        'service.overview'
      ].includes(this.$route?.name)
        ? false
        : !this.$route?.meta?.hideFooter
    }
  },

  methods: {
    updateHeader() {
      this.key++
    },
    async getVersion() {
      const response = await AuthService.version()
      if (response?.data?.version) {
        const version = StorageService.getItem('version')
        if (version !== response.data?.version) {
          StorageService.setItem('version', response.data.version)
          window.location.reload()
        }
      }
    }
  }
}
</script>
