import { CollectionService, LinkService } from '@services'
import cloneDeep from 'lodash/cloneDeep'
import concat from 'lodash/concat'
import { LINK_TYPE, COLLECTION_TYPE } from '@constants'
export default {
  data() {
    return {
      collectionList: [],
      params: {
        page: 1,
        limit: 16
      },
      currentPage: 1,
      totalPages: 0,
      totalCollection: 0,
      sharedData: null,
      actionKey: 0,
      actions: [
        {
          name: 'Share',
          value: 'share',
          icon: 'mdi-share',
          show: true
        },
        {
          name: 'Set cover image',
          value: 'setCoverImage',
          icon: 'mdi-image-size-select-actual',
          show: true
        },
        {
          name: 'Rename',
          value: 'rename',
          icon: 'mdi-form-textbox',
          show: true
        },
        {
          name: 'Remove',
          value: 'delete',
          icon: 'mdi-delete',
          show: true
        },
        {
          name: 'Remove',
          value: 'delete-link',
          icon: 'mdi-delete',
          show: true
        }
      ],
      breadCrumbPages: [],
      parent: null,
      currentCollection: null,
      intersectionObserver: null,
      rank: {
        min: null,
        max: null,
        affectedRanks: []
      },
      locationList: [],
      touchCount: 0,
      lastTouchTime: 0,
      touchTimeout: null,
      lastTap: 0,
      touchTimer: null,
      touchStartX: 0,
      touchStartY: 0,
      isTouchMoved: false,
      clickTimeout: null
    }
  },

  beforeRouteLeave(to, from, next) {
    this.destroyIntersectionObserver()
    next()
  },

  beforeDestroy() {
    if (this.intersectionObserver) {
      this.destroyIntersectionObserver()
    }
  },

  methods: {
    async getCollections(collectionSlug = null) {
      this.$loading.spinner = true
      if (collectionSlug) {
        this.params.parentId = collectionSlug
      }
      this.params.sortBy = 'rank'
      this.params.type = this.gridType
      const response = await CollectionService.getAll(this.params)
      if (response) {
        // Tempararily added for work
        if (this.params?.onlyCover) {
          this.collectionList = concat(this.collectionList, response.data?.data)
        } else {
          this.collectionList =
            this.params.page > 1
              ? concat(this.collectionList, response.data?.data)
              : response.data?.data
        }
        this.totalCollection = response.data.count || 0
        if (response.data?.totalPages) this.totalPages = response.data?.totalPages
        if (response.data?.parent) this.parent = response.data?.parent

        if (this.parent) this.$parent.parent = this.parent

        this.currentPage = this.params?.page
        //if (this.$route?.name == 'collection.share.edit') {
        if (!this.totalPages) this.totalPages = 1
      }
      this.$loading.spinner = false
    },

    async getCollectionData(linkId, params = {}, gridType = LINK_TYPE.COLLECTION) {
      this.$loading.spinner = true
      let currentLinkId = this.currentCollection?.linkId ? this.currentCollection?.linkId : linkId
      this.params.sortBy = 'rank'
      this.params.type = gridType
      this.params = { ...this.params, ...params }
      const response = await LinkService.getAll(currentLinkId, this.params)
      if (response) {
        if (this.params?.page <= 1) {
          this.collectionList = response.data?.data
        } else {
          this.collectionList = concat(this.collectionList, response.data?.data)
        }

        this.totalCollection = response.data.count
        if (response.data?.totalPages) this.totalPages = response.data?.totalPages
        this.currentPage = this.params?.page
        //if (this.$route?.name == 'collection.share.edit') {
        if (!this.totalPages) this.totalPages = 1

        // if (this.currentPage == this.totalPages) this.triggentToLoadLocations()
        //}
        // if (response.data?.parent) this.currentOpenedCollecton = response.data?.parent
      }
      this.$loading.spinner = false
    },

    async getLinkData(linkId, updateData = true) {
      const response = await LinkService.get(linkId)
      if (response) {
        if (this.$route?.params?.shareId == response.data?.linkId) {
          this.sharedData = response.data
          if (!updateData) {
            this.setCurrentOpen(this.currentCollection)
          } else {
            this.setCurrentOpen(this.sharedData)
          }
        } else {
          this.currentCollection = response.data
          this.setCurrentOpen(this.currentCollection)
        }
        this.actionKey++
      }
    },

    async getCollectionPath(collection) {
      const isCollectionSetupPage = this.$route.name == 'collection.share.edit'
      const response = await LinkService.getPath(collection?.linkId)
      if (response) {
        this.breadCrumbPages = response?.data?.map((obj, index) => {
          if (isCollectionSetupPage) {
            let privateName = obj.meta?.collectionId?.name || obj.meta?.locationId?.name
            let titleText = privateName || obj?.title
            obj = { ...obj, title: titleText }
            if (obj.level === 0) {
              titleText = `${privateName} share setup`
              obj = { ...obj, title: titleText }
            }
          }
          return obj
        })
        const copyOfData = cloneDeep(collection)
        copyOfData.disabled = true
        if (this.breadCrumbPages?.length > 0 && isCollectionSetupPage) {
          copyOfData.title =
            copyOfData.meta?.collectionId?.name || copyOfData.meta?.locationId?.name
        }
        this.breadCrumbPages.push(copyOfData)
      }
    },

    registerObserver() {
      this.intersectionObserver = new IntersectionObserver(this.handleIntersect, {
        root: null,
        rootMargin: '0px 0px -10px 0px',
        threshold: 1
      })

      this.intersectionObserver.observe(this.$refs?.endOfListIndicator)
    },

    destroyIntersectionObserver() {
      if (this.intersectionObserver) {
        this.intersectionObserver.disconnect()
        this.intersectionObserver = null
      }
    },

    async handleIntersect() {
      // const [entry] = entries

      //if (entry.isIntersecting) {
      this.params.page++
      if (this.params?.onlyCover) {
        if (
          this.totalCollection == this.collectionList?.length &&
          this.gridType == COLLECTION_TYPE.COLLECTION
        ) {
          this.gridType = COLLECTION_TYPE.LOCATION
          this.params.page = 1
          this.totalPages = 1
          this.totalCollection = 0
        }

        if (
          !this.totalCollection ||
          (this.params?.page > 1 && this.params?.page <= this.totalPages)
        ) {
          await this.getCollections(this.currentCollection)
        }
        if (
          this.gridType == COLLECTION_TYPE.LOCATION &&
          this.params?.page == 1 &&
          this.collectionList?.length == 0
        ) {
          this.recordsNotFoundFlag = true
        }
      } else if (this.totalCollection > this.collectionList?.length && !this.$loading?.spinner) {
        if (['collection.view', 'collection.list'].includes(this.$route.name)) {
          await this.getCollections(this.currentCollection)
        } else {
          await this.getCollectionData(this.currentCollection?.linkId, {}, this.gridType)
        }
      }
      //}
    },

    getName(item) {
      let displayName = item?.title || item?.name
      if (this.$route?.name === 'collection.share.edit') {
        if (item?.meta?.locationId) {
          displayName = item?.meta?.locationId?.name
        }
        if (item?.meta?.collectionId) {
          displayName = item?.meta?.collectionId?.name
        }
      }
      return displayName
    },

    findMinMaxNumbersWithIndexes(affectedRanks) {
      return affectedRanks.reduce(
        (result, obj) => {
          ;['oldIndex', 'newIndex'].forEach((key) => {
            if (obj[key] < result.min.value) {
              result.min = { value: obj[key], index: key }
            }
            if (obj[key] > result.max.value) {
              result.max = { value: obj[key], index: key }
            }
          })
          return result
        },
        {
          min: { value: Number.MAX_VALUE, index: '' },
          max: { value: Number.MIN_VALUE, index: '' }
        }
      )
    },

    retrieveAffectedRanks(event) {
      if (event.newIndicies?.length) {
        event.newIndicies.forEach((_item) => {
          const sortableIndex = _item.multiDragElement?.sortableIndex
          const newIndex = _item.index
          const oldIndexMedia = this.collectionList[sortableIndex]
          const newIndexMedia = this.collectionList[newIndex]
          if (oldIndexMedia && newIndexMedia && oldIndexMedia?.rank != newIndexMedia?.rank) {
            this.rank.affectedRanks.push({
              oldIndex: oldIndexMedia.rank,
              newIndex: newIndexMedia.rank
            })
          }
        })
      } else {
        const oldIndexMedia = this.collectionList[event.oldIndex]
        const newIndexMedia = this.collectionList[event.newIndex]
        if (oldIndexMedia && newIndexMedia && oldIndexMedia?.rank != newIndexMedia?.rank) {
          this.rank.affectedRanks.push({
            oldIndex: oldIndexMedia.rank,
            newIndex: newIndexMedia.rank
          })
        }
      }
      if (this.rank?.affectedRanks?.length) {
        const { min, max } = this.findMinMaxNumbersWithIndexes(this.rank.affectedRanks)
        this.rank.min = min
        this.rank.max = max
        if (['collection.view', 'collection.list'].includes(this.$route?.name)) {
          this.collectionChangeRank()
        } else {
          this.changeRank()
        }
      }
    },

    resetRank() {
      this.rank = {
        min: null,
        max: null,
        affectedRanks: []
      }
    },

    selectItemToggle(event) {
      const itemCard = event?.target?.closest('.item-card')
      if (itemCard && itemCard.classList) {
        if (itemCard.classList.contains('selected')) {
          itemCard.classList.remove('selected')
        } else {
          itemCard.classList.add('selected')
        }
      }
    },

    handleClick(event, itemData) {
      const DOUBLE_TAP_DELAY = 300 // ms
      const currentTime = new Date().getTime()
      const tapLength = currentTime - this.lastTouchTime

      if (tapLength < DOUBLE_TAP_DELAY && tapLength > 0) {
        if (this.clickTimeout) {
          window.clearTimeout(this.clickTimeout)
          this.clickTimeout = null
        }
        this.openCollectionDetails(itemData)
      } else {
        if (this.breakpoint.mdAndDown) {
          this.clickTimeout = window.setTimeout(() => {
            this.selectItemToggle(event)
            this.getSelectedItems()
            this.clickTimeout = null
          }, DOUBLE_TAP_DELAY)
        }
      }

      this.lastTouchTime = currentTime
    },

    onTouchStart(event, itemData = null) {
      this.touchStartX = event.touches[0].clientX
      this.touchStartY = event.touches[0].clientY
      this.touchCount++
      this.isTouchMoved = false
      if (this.touchCount === 1) {
        this.touchTimeout = setTimeout(() => {
          this.touchCount = 0
        }, 300) // Adjust the duration according to your requirement
      } else if (this.touchCount === 2) {
        if (!this.isTouchMoved) {
          clearTimeout(this.touchTimeout)
          this.touchCount = 0
          const now = new Date().getTime()
          this.openCollectionDetails(itemData)
          this.lastTouchTime = now
        }
      }
    },

    handleTouchMove(event) {
      const touchEndY = event.touches[0].clientY
      const deltaY = Math.abs(touchEndY - this.touchStartY)

      if (deltaY > 5) {
        this.isTouchMoved = true
        //clearTimeout(this.touchTimeout)
      }
    },

    onTouchEnd() {
      if (this.touchTimeout > 299) {
        clearTimeout(this.touchTimeout)
        this.touchCount = 0
        this.isTouchMoved = false
      }
    },

    handleTouchGetSelectedItems(event) {
      event.preventDefault()
      setTimeout(() => {
        this.getSelectedItems()
      }, 300)
    },

    updateLimit() {
      if (this.breakpoint.md) {
        this.params.limit = 16
      } else if (this.breakpoint.sm) {
        this.params.limit = 12
      } else if (this.breakpoint.xs) {
        this.params.limit = 12
      } else if (this.breakpoint.lg) {
        this.params.limit = 20
      } else {
        this.params.limit = 18 // Default value
      }
    }
  }
}
