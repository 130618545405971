<template>
  <div class="divide-y">
    <nav class="flex flex-1 flex-col py-5">
      <ul role="list" class="flex flex-1 flex-col gap-y-7">
        <li>
          <ul role="list" class="-mx-2 space-y-1">
            <li v-for="item in navigation" :key="item.name" class="w-full flex justify-between">
              <router-link :to="item.link" class="w-full">
                <div
                  class="w-full"
                  :class="[
                    item.current
                      ? 'bg-gray-50 text-primary font-bold'
                      : 'text-gray-700 hover:text-primary  hover:bg-gray-50',
                    !item.enabled && 'cursor-default hover:bg-light text-grey',
                    'flex justify-between rounded-md p-2 text-sm leading-6 font-semibold'
                  ]"
                >
                  <span>
                    {{ item.name }}
                  </span>
                  <Icon class="text-primary size-6" icon="mdi-chevron-right"></Icon>
                </div>
              </router-link>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
    <div v-if="$route?.params?.collectionId" class="pt-5">
      <div class="flex justify-start items-center text-wrap">
        <span class="text-base"> Sharing {{ parentData?.name }}</span>
      </div>
      <div class="flex justify-end items-center py-3">
        <button @click="shareCollection" class="px-3 py-1.5 text-sm rounded bg-primary">
          Create new link
        </button>
      </div>
      <template v-if="totalLinks > 0">
        <div class="text-dark py-2 pt-3 text-sm">{{ totalLinks }} share links</div>
        <template v-if="links?.length">
          <div v-for="(link, index) in links" :key="index" class="py-1">
            <div class="flex items-center justify-between">
              <div class="text-overflow">
                <span
                  class="text-sm text-gray-400"
                  :class="[
                    link?.status == LINK_STATUS?.PUBLISHED && 'text-success font-semibold',
                    link?.status == LINK_STATUS?.UNPUBLISHED && 'text-error'
                  ]"
                  >{{ link?.secretNote || link?.linkId }}
                </span>
              </div>
              <div class="flex items-center">
                <!-- <button @click="viewLink(link)" class="hover:bg-gray-200 rounded-full pa-2">
                  <Icon icon="mdi-eye-outline" class="size-6 text-primary"></Icon>
                </button> -->
                <ActionMenu
                  v-if="actionsList"
                  :actions="getActionsList(link)"
                  @menuAction="menuAction"
                  :actionData="link"
                  :menuItemsClass="'right-0 w-40'"
                />
              </div>
            </div>
          </div>
        </template>
        <div
          class="pt-4 flex items-center gap-1"
          :class="totalLinks < 3 ? 'justify-center' : 'justify-between'"
        >
          <button
            @click="$next({ name: 'shared.links' })"
            class="tw-btn tw-primary-outlined-btn text-sm ring-1 px-2"
          >
            View all links
          </button>
          <button
            v-if="totalLinks > 3"
            @click="viewMore"
            class="tw-btn tw-primary-outlined-btn text-sm ring-1 px-2"
          >
            View more
          </button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { LINK_STATUS } from '@constants'
import ActionMenu from '@components/ActionMenu.vue'
import { SharedLinksMixin, CollectionMixin } from '@mixins'
import { LinkService } from '@services'
export default {
  name: 'SideBarContent',

  props: {
    parentData: { type: Object, default: null }
  },

  mixins: [SharedLinksMixin, CollectionMixin],

  components: {
    ActionMenu
  },

  data() {
    return {
      navigation: [
        {
          name: 'All collections',
          link: { name: 'collection.list' },
          enabled: true
        }
      ],
      links: [],
      totalLinks: 0,
      LINK_STATUS
    }
  },

  async mounted() {
    if (this.parentData?._id) {
      await this.getAllSharedLinks()
    }
  },

  methods: {
    async shareCollection() {
      const params = {
        linkId: this.generateRandomId(10),
        collectionId: this.parentData?._id
      }

      const response = await LinkService.save(params, {
        hideSuccessMessage: true
      })

      if (response) {
        this.$next({
          name: 'collection.share.edit',
          params: { collectionId: this.parentData?.slug, shareId: response?.data?.linkId }
        })
      }
    },

    async getAllSharedLinks() {
      const response = await LinkService.getHistory({ limit: 3, id: this.parentData?._id })
      if (response) {
        this.totalLinks = response.data?.count || 0
        this.links = response.data.data
      }
    },
    viewMore() {
      this.$next({
        name: 'shared.links-list',
        params: { id: this.parentData?._id }
      })
    }
  }
}
</script>
