<template>
  <TWDialogWrapper :show="dialog">
    <DialogPanel
      class="relative transform overflow-visible rounded-lg bg-white text-left shadow-xl transition-all sm:w-full sm:max-w-lg pa-8"
    >
      <div class="text-left">
        <DialogTitle as="h3" class="text-base font-semibold leading-6 text-primary"
          >{{ operation == 'create' ? 'New' : 'Rename' }} collection</DialogTitle
        >
      </div>
      <div class="mx-auto max-w-lg">
        <vee-form ref="collectionForm" :validation-schema="schema" @submit="submitCollection">
          <div class="py-4">
            <TextField name="name" type="text" class="text-dark" :title="nameFieldLabel" />
          </div>
          <div class="flex justify-end pt-4">
            <button class="tw-btn tw-cancel-btn px-5" @click="$emit('closeDialog')">Cancel</button>
            <button type="submit" class="tw-btn tw-primary-btn ml-3 text-light min-w-[80px]">
              <AnimateSpin v-if="loading" />
              <span v-else> Save </span>
            </button>
          </div>
        </vee-form>
      </div>
    </DialogPanel>
  </TWDialogWrapper>
</template>

<script>
import * as yup from 'yup'
import TextField from '@form-fields/TextField.vue'
import { DialogPanel, DialogTitle } from '@headlessui/vue'
import AnimateSpin from '@components/icons/AnimateSpin.vue'
import TWDialogWrapper from '@components/TWDialogWrapper.vue'
import { UtilityService, CollectionService, StorageService } from '@/services'
import { CollectionMixin } from '@mixins'

export default {
  name: 'CreateCollectionDialog',

  emits: ['closeDialog'],

  mixins: [CollectionMixin],

  props: {
    dialog: { type: Boolean, default: false },
    operation: { type: String, default: 'create' },
    collectionData: { type: Object, default: {} }
  },

  components: {
    DialogPanel,
    DialogTitle,
    AnimateSpin,
    TextField,
    TWDialogWrapper
  },

  data() {
    return {
      loading: false,
      schema: yup.object({
        name: yup.string().required('Collection name is required')
      }),
      nameFieldLabel: this.operation == 'edit' ? 'Name your collection' : 'Name your new collection'
    }
  },

  created() {
    setTimeout(() => {
      if (this.collectionData?.name) {
        this.$refs?.collectionForm?.setFieldValue('name', this.collectionData?.name)
      }
    }, 100)
  },
  methods: {
    async submitCollection(values) {
      const parentId = this.$route?.params?.collectionId
        ? this.$route.params.collectionId
        : undefined
      this.loading = true
      let params = {
        name: values.name
      }
      if (this.operation == 'edit' && this.collectionData?._id) {
        params._id = this.collectionData?._id
      } else {
        params.slug = UtilityService.slugify(values.name)
        params.parentId = parentId
      }

      const response = await CollectionService.save(params)
      if (response) {
        this.$emit('closeDialog')
        let collectionSlug = undefined
        if (this.$route?.params?.collectionId) collectionSlug = this.$route.params.collectionId

        if (response.data?.slug && this.operation == 'create') {
          const collectionSlug = response.data?.slug
          await this.addItemToBreadCrumb({ slug: collectionSlug, name: params.name })
          this.$next({
            name: 'collection.manage',
            params: { collectionId: collectionSlug }
          })
        } else {
          this.emitter.emit('getCollections', collectionSlug)
        }
      }
      this.loading = false
    },
    addItemToBreadCrumb(collection) {
      this.pages = JSON.parse(StorageService.getSessionItem('breadcrumbItems')) || []

      if (!this.pages.some((page) => page?.slug == collection?.slug)) {
        this.pages.push({
          link: `/a/${this.$route?.params?.accountId}/a/collections/${collection?.slug}`,
          text: collection?.name,
          routeName: 'collection.view',
          slug: collection?.slug
        })
        StorageService.setSessionItem('breadcrumbItems', JSON.stringify(this.pages))
      }
    }
  }
}
</script>
