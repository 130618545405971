<template>
  <Popover as="div" class="relative ml-3">
    <div>
      <PopoverButton class="relative flex max-w-xs items-center rounded-full bg-white text-sm">
        <span class="absolute -inset-1.5" />
        <span class="sr-only">Open user menu</span>
        <UserAvatar :user="$user" :color="'primary'" />
      </PopoverButton>
    </div>
    <transition
      enter-active-class="transition ease-out duration-200"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <PopoverPanel
        v-slot="{ close }"
        :class="breakpoint.xs && $route?.name == 'search.view' ? '-top-2 -right-3' : 'top-12'"
        class="absolute right-0 z-10 w-72 h-[39rem] scrollbar-hidden origin-top-right rounded-md bg-white shadow-xl ring-1 ring-black ring-opacity-5 focus:outline-none overflow-y-auto"
        :style="`max-height:${!breakpoint.xs ? 'calc(100vh - 5rem)' : '78vh'}`"
      >
        <div
          v-if="breakpoint?.xs && $route?.name == 'search.view'"
          class="flex py-1 px-4 h-16 justify-between"
        >
          <PopoverButton class="relative flex max-w-xs items-center rounded-full bg-white text-sm">
            <span class="absolute -inset-1.5" />
            <span class="sr-only">Open user menu</span>
            <UserAvatar :user="$user" :color="'primary'" />
            <Icon icon="mdi-chevron-right" class="size-10 text-primary"></Icon>
          </PopoverButton>
          <div
            class="logo-img w-8/12 flex items-center"
            :class="$account?.media?.key && 'justify-end'"
          >
            <Logo :src="$getImage($account?.media?.original, 400)" />
          </div>
        </div>

        <template v-if="$user?.accounts.length">
          <span class="mx-4 text-caption">Workspaces:</span>
          <div :class="'block text-sm text-gray-700'">
            <AccountsMenu :menu="menu" @closeMenu="close()" @openMenu="menu = true" />
          </div>
        </template>
        <template v-if="$isSubscribed && $accountId">
          <div class="border-b">
            <ul role="list">
              <li
                class="px-4 flex items-center py-3 hover:bg-gray-100 cursor-pointer"
                v-for="(item, i) in navigation"
                :key="i"
                :value="item"
                color="primary"
                :class="item.classes"
                :disabled="item.name === 'Edit locations' && this.$isPlanCancelled"
                active-class="white--text"
                @click="$next(item.link), close()"
              >
                <Icon class="text-primary size-6 mr-8" :icon="`${item.icon}`"></Icon>
                <span class="text-base font-thin">{{ item.name }}</span>
              </li>
            </ul>
          </div>
        </template>
        <span class="mx-4 text-caption"> Currently signed in as:</span>

        <div class="flex items-center pa-3">
          <UserAvatar :user="$user" :color="'primary'" />

          <div class="px-2 pl-3">
            <span class="flex">{{ $user?.firstName }} {{ $user?.lastName }}</span>
            <span class="text-sm text-dark">{{ $user?.email }}</span>
          </div>
        </div>
        <div
          class="block md:hidden py-3 px-4 hover:bg-gray-100 cursor-pointer"
          @click="emitter.emit('openDialog', true)"
        >
          <div class="flex items-center">
            <Icon class="size-6 mr-5" icon="mdi-message-alert-outline" />
            <span class="px-2 pl-3"> Give feedback </span>
          </div>
        </div>
        <div
          class="px-4 flex items-center py-3 hover:bg-gray-100 cursor-pointer"
          @click="manualLogout()"
        >
          <Icon class="size-6 mr-5" icon="mdi-logout"></Icon>
          <span class="px-2 pl-3">Logout</span>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>

<script>
import { AuthMixin, VenueMixin, ImageMixin } from '@mixins'
import UserAvatar from '@components/UserAvatar.vue'
import AccountsMenu from '@components/AccountsMenu.vue'
import Logo from '@components/Logo.vue'

import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'

export default {
  name: 'PopoverMenu',
  mixins: [AuthMixin, VenueMixin, ImageMixin],
  components: {
    UserAvatar,
    AccountsMenu,
    Popover,
    PopoverButton,
    PopoverPanel,
    Logo
  },
  data() {
    return {
      menu: false
    }
  },
  methods: {
    mySettings() {
      this.menu = false
    }
  }
}
</script>

<style lang="scss">
.logo-img {
  overflow: hidden !important;
}
.account-btn {
  height: 40px !important;
  width: 40px !important;
  min-width: 40px !important;
  padding: 0 !important;

  .v-btn__content {
    max-width: unset !important;
  }
}
</style>
