<template>
  <TWDialogWrapper :show="dialog">
    <DialogPanel
      :class="[
        'w-full md:w-3/6 transform overflow-hidden rounded bg-white p-8 text-left align-middle shadow-xl transition-all'
      ]"
    >
      <DialogTitle class="text-md md:text-xl text-black text-center mb-2 md:pt-4 md:px-6">
        <div class="font-extralight" v-html="options.title"></div>
      </DialogTitle>
      <div v-if="options.subtitle" class="mt-2">
        <p class="text-md text-gray-500 text-center" v-html="options.subtitle"></p>
      </div>

      <div class="pt-8 flex justify-center">
        <button
          v-if="!options.hideCancelButton"
          class="tw-btn tw-cancel-btn mr-4"
          @click="onClick(false)"
        >
          Cancel
        </button>

        <button
          v-if="!options.hideCancelButton"
          class="tw-btn tw-primary-btn px-6 ring-1 ring-primary"
          @click="onClick(true)"
        >
          {{ options.confirmButtonText || 'Yes' }}
        </button>
      </div>

      <div
        v-if="options.footer"
        class="mt-4 text-center text-sm text-gray-500"
        v-html="options.footer"
      ></div>
    </DialogPanel>
  </TWDialogWrapper>
</template>

<script>
import { DialogPanel, DialogTitle } from '@headlessui/vue'
import TWDialogWrapper from './TWDialogWrapper.vue'
export default {
  name: 'TheAlert',

  data() {
    return {
      dialog: false,
      title: null,
      options: {},
      subtitle: null,
      callback: null
    }
  },

  components: {
    DialogPanel,
    DialogTitle,
    TWDialogWrapper
  },
  mounted() {
    this.emitter.on('confirm', this.onConfirm)
  },
  beforeUnmount() {
    this.emitter.off('confirm', this.onConfirm)
  },
  methods: {
    onClick(value) {
      this.dialog = false
      if (typeof this.callback === 'function') {
        this.callback(value)
      }
    },
    onConfirm(options) {
      this.options.title = `<p>${options?.title}</p>`
      this.options.subtitle = options?.subtitle
      this.options.footer = options?.footer
      this.options.width = options?.width || '800px'
      this.options.confirmButtonText = options?.confirmButtonText
      this.options.hideCancelButton = options?.hideCancelButton
      this.options.persistent = !!options?.persistent
      this.callback = options?.callback
      this.dialog = true
    }
  }
}
</script>
