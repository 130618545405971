import authRoutes from './auth.route'
import homeRoutes from './home.route'
import pagesRoutes from './pages.route'
import userRoutes from './user.route'
import profileRoutes from './profile.route'
import publicRoutes from './public.route'
import DefaultLayout from '@layouts/DefaultLayout.vue'
import { defineAsyncComponent } from 'vue'

// Import or define your NotFound component
const NotFound = defineAsyncComponent(() => import('@components/NotFound.vue'))

let childrenRoutes = []

childrenRoutes.push(...authRoutes, ...pagesRoutes, ...userRoutes, ...profileRoutes)

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/search',
    component: DefaultLayout,
    children: childrenRoutes,
    meta: {
      link: (route) => `/`,
      breadcrumb: (name) => name || 'Library'
    }
  },
  // Add the 404 route as the last one
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound,
    meta: {
      breadcrumb: () => '404 Not Found'
    }
  }
]

routes.push(...publicRoutes)

export default routes
